import React, { useEffect, useState } from 'react';
import { renderDynamicComponents } from '../../components/cms-modules';
import Layout from '../../modules/layout';
import WDIRankingFilterList from '../../components/wdi-ranking/wdiFilterList';
import JuryFilterList from '../../components/jury/filterList/filterList';
import ArticlesFilterView from '../../components/cms-modules/editorialTeaser/components/articlesFilterView';

const ContentPage = ({ pageContext }) => {
  const {
    title,
    seoDescription,
    content,
    heroTeaser,
    heroCarouselItems,
    heroCarouselTeaser,
    metaNaviWhite,
    hasWhiteNavigation,
    node_locale,
    footerColor,
    previewImage,
    hideHeaderAndFooter,
    showAnchorLinks,
    filterlist,
  } = pageContext;
  const [previewImageUrl, setPreviewImageUrl] = useState(
    previewImage?.file?.url
  );

  useEffect(() => {
    // for iF Ranking filterlist, we want the og:image to be a screenshot of the ranking
    if (filterlist === 'iF Ranking') {
      setPreviewImageUrl(
        `${process.env.GATSBY_SCREENSHOT_SERVICE_URL}?url=${encodeURIComponent(
          location.href
        )}&anchorId=resultList&viewportWidth=1280&viewportHeight=1280`
      );
    } else {
      setPreviewImageUrl(
        heroTeaser?.background?.image?.file?.url || previewImage?.file?.url
      );
    }
  }, []);

  const getFilterlist = (filterlist) => {
    switch (filterlist) {
      case 'Juror':
        return <JuryFilterList includeSearch />;
      case 'Magazine':
        return <ArticlesFilterView hideFilterOnLoad />;
      case 'iFDA':
      case 'SIP':
      case 'Student Award':
      case 'Company':
        return null;
      case 'iF Ranking':
        return <WDIRankingFilterList />;
      default:
        return null;
    }
  };

  return hideHeaderAndFooter ? (
    content && renderDynamicComponents(content, node_locale)
  ) : (
    <Layout
      title={title}
      description={seoDescription}
      heroTeaser={heroTeaser}
      heroCarouselItems={heroCarouselItems}
      heroCarouselTeaser={heroCarouselTeaser}
      hasWhiteNavigation={hasWhiteNavigation}
      metaNaviWhite={metaNaviWhite}
      isFooterBeige={footerColor === 'Beige' ? true : false}
      previewImage={previewImageUrl}
      anchorLinks={
        showAnchorLinks
          ? content
              .filter((component) => component.anchorLabel)
              .map((component) => ({
                label: component.anchorLabel,
                anchorId: component.description,
              }))
          : []
      }
    >
      {content && renderDynamicComponents(content, node_locale)}
      {filterlist && getFilterlist(filterlist)}
    </Layout>
  );
};

export default ContentPage;
